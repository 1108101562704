<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Templates Attributes'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Templates Attributes Edit'"
      :title="'Templates Attributes Edit'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Update</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
        <div v-if="langList.length" class="form-create__langs">
          <div
            v-for="lang in langList"
            :key="lang.id"
            @click="openLangModal(lang)"
            class="form-create__lang"
          >
            {{ lang.seoName }}
          </div>
        </div>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>

          <p class="form-create__label">SEO Name (for URL)</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'seoName'"
              :label="'seo name'"
              :value="formDataToSend.seo_name"
              :type="'text'"
              :placeholder="'SEO Name'"
              :error-messages="validationErrors['seoName']"
              readonly
              isBordered
            />
          </div>
          <p class="form-create__label">Link (url)</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'link'"
              :label="'link'"
              :type="'text'"
              :placeholder="'Link (url)'"
              :error-messages="validationErrors['link']"
              isBordered
            />
          </div>

          <p class="form-create__label">Categories</p>
          <input
            v-model="categorySearch"
            class="form-create__search"
            type="text"
            placeholder="Search category..."
            @input="debouncedGetCategories"
          />

          <div class="input-container checkbox-container checkbox-container_margin">
            <div class="checkbox-container__item">
              <InputRadio
                :set-value="formDataToSend"
                :id="'category'"
                :values="categoriesList"
                :name="'fullName'"
                :value="'id'"
              />
            </div>
          </div>

          <div class="input-container">
            <MainCheckbox :label="'Active'" :id="'status'" :set-value="formDataToSend" />
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
        <MainModal v-if="langModal" :on-close="closeLangModal" :modal-center="true">
          <div class="lang-modal">
            <div class="lang-modal__title">Translations for {{ chosenLang.name }}</div>
            <form @submit.prevent="handleSubmitLang">
              <p class="form-create__label">Name</p>
              <div class="input-container">
                <InputText
                  :set-value="formDataToSendLang"
                  :id="'name'"
                  :label="'name'"
                  :type="'text'"
                  :placeholder="'name'"
                  isBordered
                />
                <div
                  @click="handleTranslate('name', chosenLang.name)"
                  class="form-create__translate"
                >
                  Translate (Chat GPT)
                </div>
              </div>
              <div class="form-create__btn">
                <MainButton :type="'submit'">Update</MainButton>
              </div>
            </form>
          </div>
        </MainModal>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import langApi from "~/api/lang";
import { debounce } from "lodash";
import categoriesApi from "~/api/categories";
import referencesApi from "~/api/references";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import InputRadio from "~/components/atoms/inputs/InputRadio.vue";
import references from "../../../api/references";

export default {
  name: "TemplatesAttributesEdit",
  metaInfo: {
    title: "Templates Attributes Edit",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        seo_name: "",
        status: false,
        category: null,
      },
      formDataToSendLang: {
        name: "",
      },
      langEdit: false,
      langModal: false,
      chosenLang: null,
      static: null,
      categoriesList: [],
      categorySearch: "",
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    MainCheckbox,
    MainModal,
    InputRadio,
  },
  mounted() {
    this.getItem();
    this.getLang();
    this.getCategories();
  },
  computed: {
    ...mapState("references", ["error", "loadingProcess", "validationErrors"]),
    ...mapState("lang", ["lang", "langList"]),
    getFirstLink() {
      return ROUTE.TEMPLATES_ATTRIBUTES_LIST.replace(":page", 1);
    },
    getId() {
      return this.$route.params.id;
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        name: this.formDataToSend.name,
        seo_name: this.formDataToSend.seo_name,
        status: this.formDataToSend.status,
        category_id: this.formDataToSend.category,
      };

      this.$store.commit("references/setLoading", true);
      const url = `/${this.getId}`;
      try {
        templatesApi
          .editHolidays(url, data)
          .then((res) => {
            if (res.success) {
              this.$store.commit("references/setError", {});
              this.$store.commit("references/setValidationErrors", {});
              this.handleBackToList();
            }
          })
          .catch((error) => {
            const { data } = error.response;
            this.$store.commit("references/setError", data);
            if (data.errors) {
              this.$store.commit("references/setValidationErrors", data.errors);
            }
          })
          .finally(() => {
            this.$store.commit("references/setLoading", false);
          });
      } catch (error) {
        console.log(error);
      }
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.TEMPLATES_ATTRIBUTES_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
    },
    getItem() {
      const url = `/${this.getId}`;
      try {
        referencesApi.getItemTemplatesAttributes(url).then((res) => {
          this.$store.commit("references/setError", {});
          this.$store.commit("references/setValidationErrors", {});
          const data = res.data;
          this.static = res.data;
          this.formDataToSend.name = data.name;
          this.formDataToSend.seo_name = data.seoName;
          this.formDataToSend.category = data.categoryId;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getLang() {
      let url = `?page=${this.page}`;
      this.$store.dispatch("lang/getLangList", url);
    },
    closeLangModal() {
      this.chosenLang = null;
      this.langModal = false;
      this.formDataToSendLang.name = "";
      this.langEdit = false;
    },
    openLangModal(lang) {
      this.langModal = true;
      this.chosenLang = lang;

      const translate = this.static.translation;
      console.log(this.chosenLang.id);
      let translateStatic = translate.find((item) => item.langId === this.chosenLang.id);
      if (translateStatic) {
        this.formDataToSendLang.name = translateStatic.name;
        this.formDataToSendLang.id = translateStatic.id;
        this.langEdit = true;
      }
    },
    handleSubmitLang() {
      this.$store.commit("references/setLoading", true);
      const data = {
        name: this.formDataToSendLang.name,
        link_name: this.formDataToSendLang.link_name,
        holiday_id: this.static.id,
        lang_id: this.chosenLang.id,
      };
      if (this.langEdit) {
        langApi
          .editLangAttributes(data, `/${this.formDataToSendLang.id}`)
          .then(() => {
            this.$store.commit("references/setLoading", false);
            this.closeLangModal();
            this.getItem();
          })
          .catch((error) => {
            this.$store.commit("references/setLoading", false);
            console.log(error);
          });
      } else {
        langApi
          .createLangAttributes(data)
          .then(() => {
            this.$store.commit("references/setLoading", false);
            this.closeLangModal();
            this.getItem();
          })
          .catch((error) => {
            this.$store.commit("references/setLoading", false);
            console.log(error);
          });
      }
    },
    handleTranslate(el, lang) {
      this.$store.commit("references/setLoading", true);
      const data = {
        text: this.formDataToSend[el],
        language: lang,
      };
      langApi
        .getTranslation(data)
        .then((res) => {
          this.$store.commit("references/setLoading", false);
          this.formDataToSendLang[el] = res.data.response;
        })
        .catch((error) => {
          this.$store.commit("references/setLoading", false);
          console.log(error);
        });
    },

    getCategories() {
      if (this.categorySearch.trim().length > 0) {
        const data = {
          search: this.categorySearch.trim(),
        };
        try {
          categoriesApi.getCategoriesListFilter("", data).then((res) => {
            const data = res.data.items.data;
            this.categoriesList = data;
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          categoriesApi.getCategoriesActive().then((res) => {
            const data = res.data.items;
            this.categoriesList = data;
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    debouncedGetCategories: debounce(function () {
      this.getCategories();
    }, 300),
  },
};
</script>

<style lang="scss" scoped>
.form-create {
  &__search {
    font-size: 18px;
    margin-top: 20px;
    width: 400px;
    padding: 5px 10px;
  }
}
.input-select {
  max-width: 300px;
}
.checkbox-container {
  @include scrollBar;

  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;

  &_margin {
    margin-top: 20px;
  }
}
.checkbox-container__item {
  padding: 5px 0;
}
</style>
